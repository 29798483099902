import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class MasterDetailRouteReuseStrategy implements RouteReuseStrategy {
  public storedRouteHandles: Map<string, DetachedRouteHandle> = new Map<string, DetachedRouteHandle>();
  public routesToCache: string[] = [
    'assets',
    'servicetickets',
    'items',
    'documents/offers',
    'documents/orders',
    'documents/deliveries',
    'documents/invoices',
    'documents/creditnotes',
    'documents/baskets',
    'oci/basket',
    'cxml/basket',
    'billingaccounts',
    'servicerecords',
    'credits',
    'licenses',
  ];
  public static skipRouteAttach = false;

  public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return (route.data.cacheKey && this.storedRouteHandles.get(route.data.cacheKey)) || null;
  }

  public shouldAttach(route: ActivatedRouteSnapshot): boolean {
    if (route.routeConfig.data && !route.routeConfig.data.cacheDetailView && !this.storedRouteHandles.has(route.routeConfig.data.cacheKey)) {
      this.storedRouteHandles.forEach((_storedHandle, key) => {
        if (key !== route.data.cacheKey) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (this.storedRouteHandles?.get(key) as any)?.componentRef?.destroy();
        }
      });

      this.storedRouteHandles.clear();
    }

    return (route.data.cacheKey && this.storedRouteHandles.has(route.data.cacheKey) && !MasterDetailRouteReuseStrategy.skipRouteAttach) || false;
  }

  public shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return route.data.cacheKey && this.routesToCache.includes(route.data.cacheKey);
  }

  public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig && !(future.routeConfig?.data?.cacheDetailView && curr.routeConfig?.data?.cacheDetailView);
  }

  public store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
    if (handle === null || !route.data.cacheKey) {
      this.storedRouteHandles.delete(this.storedRouteHandles.keys()[this.storedRouteHandles.size - 1]);
    } else {
      this.storedRouteHandles.set(route.data.cacheKey, handle);
    }
  }
}
